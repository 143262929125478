<template>
  <div class="row justify-content-center">
    <div class="col-lg-10 col-xl-12">
      <transition name="slide-fade" mode="out-in">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchPage"
};
</script>

<style scoped></style>
